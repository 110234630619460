export const ACTION_TYPE_FILTER = [
  {
    text: "Client register new business account",
    value: "CreateAccountRegistration",
  },
  {
    text: "Client upload file for company registration",
    value: "UploadFileCompanyRegister",
  },
  {
    text: "Client resubmit application for rejected case",
    value: "ResubmitAccountRegistration",
  },
  {
    text: "Client send invitation for their Tier 1",
    value: "SingleInvitation",
  },
  { text: "Client bulk invite their Tier 1 clients", value: "BulkInvitation" },
  {
    text: "Client accept invitation from their inviter",
    value: "AcceptInvitation",
  },
  {
    text: "Client reject invitation from their inviter",
    value: "RejectInvitation",
  },
  {
    text: "Client resend invitation for their Tier 1",
    value: "ResendInvitePartner",
  },

  { text: "Internal User add new Client", value: "CreateNewClient" },
  {
    text: "Internal user approve application",
    value: "ApproveAccountRegistration",
  },
  {
    text: "Internal user reject application",
    value: "RejectAccountRegistration",
  },
];

export const RELATED_FEATURE_FILTER = [
  { text: "Account Registration", value: "AccountRegistration" },
];

export const TYPE_OF_CLIENT_FILTER = [
  { text: "Upstream Partner", value: 0 },
  { text: "Supply Chain Partner", value: 1 },
];
