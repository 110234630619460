import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-10 py-4 pb-8 mt-5" }
const _hoisted_2 = { class: "flex items-center justify-between py-2" }
const _hoisted_3 = { class: "flex items-center justify-between gap-2 pt-2 w-full mb-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "shadow-lg rounded-lg bg-white" }
const _hoisted_6 = { class: "flex items-center justify-center" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex-grow text-center w-full" }

import { computed, ComputedRef, onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { STATUS_TENANT } from "@ems/constants/account_registration_status";
import { ITenantParameters } from "@/models/Tenant";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import {
  formatDateTime,
  formatInputDate,
} from "@/utils/helpers/convertDateTime";
import { getEnumValue } from "@/utils/helpers/getEnumValue";
import TenantBadge from "@/components/Common/Status/TenantBadge.vue";
import SearchInput from "@/components/Common/SearchHelper/SearchInput.vue";
import { SEARCH_TEXT } from "@ems/locales/search";
import { SYSTEM } from "@ems/locales/system";
import MetadataModule from "@/store/modules/Metadata";
import { ICountries } from "@/models/Metadata";
import SelectFilter from "@/components/Common/FilterHelper/SelectFilter.vue";
import { TYPE_OF_CLIENT_FILTER } from "@ems/constants/filter";
import InvitationBadge from "@/components/Common/Status/InvitationBadge.vue";
import { SUPPLYCHAIN_PARTNER, UPSTREAM_PARTNER } from "@ems/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const filterOptions = computed(() => [
  "All",
  "Invitation Sent",
  "Pending",
  "Approved",
  "Rejected",
]);

const allCountries: ComputedRef<ICountries[]> = computed(
  () => MetadataModule.dataCountriesNewGetter
);

const disabled = ref(false);
const small = ref(true);
const currentPage = ref(1);
const pageSize = ref(10);
const activeFilter = ref("All");
const searchText = ref("");
const sortBy = ref("");
const sortOrder = ref(1);
const isSearch = ref(false);
const saveInputSearch = ref("");
const optionFilter = ref({
  country: [],
  typeOfClient: [],
});
const countryListFilter = ref<any>();
const visibleFilter = ref({
  country: false,
  typeOfClient: false,
});

const router = useRouter();
const route = useRoute();
const tenantAccountList = computed(
  () => TenantUserModule.dataGetListTenantGetter
);

const paginationGet = computed(() => TenantUserModule.paginationGetter);

const isLoadingDataListTenant = computed(
  () => TenantUserModule.isLoadingDataListTenantGetter
);

const handleFilterCountry = (selectedOptions: any) => {
  console.log(selectedOptions);
  optionFilter.value.country = selectedOptions;
  currentPage.value = 1;
  getTenantAccount({
    Search: isSearch.value ? saveInputSearch.value : "",
    CountryCodes: optionFilter.value.country,
    ClientTypes: optionFilter.value.typeOfClient,
  });
};

const handleFilterTypeOfClient = (selectedOptions: any) => {
  optionFilter.value.typeOfClient = selectedOptions;
  currentPage.value = 1;
  getTenantAccount({
    Search: isSearch.value ? saveInputSearch.value : "",
    CountryCodes: optionFilter.value.country,
    ClientTypes: optionFilter.value.typeOfClient,
  });
};

const handleRegisterClient = () => {
  router.push(`${route.path}/register-client`);
};

const handleFilterStatus = (filter: string) => {
  currentPage.value = 1;
  activeFilter.value = filter;
  getTenantAccount({
    Search: isSearch.value ? saveInputSearch.value : "",
    Status:
      activeFilter.value === "All"
        ? ""
        : activeFilter.value === "Invitation Sent"
        ? "Sent"
        : activeFilter.value,
    CountryCodes: optionFilter.value.country,
    ClientTypes: optionFilter.value.typeOfClient,
    CurrentPage: currentPage.value,
  });
};

const handleSizeChange = (val: number) => {
  currentPage.value = 1;
  pageSize.value = val;
  getTenantAccount({
    Search: isSearch.value ? saveInputSearch.value : "",
    Status:
      activeFilter.value === "All"
        ? ""
        : activeFilter.value === "Invitation Sent"
        ? "Sent"
        : activeFilter.value,
    CountryCodes: optionFilter.value.country,
    ClientTypes: optionFilter.value.typeOfClient,
  });
};

const handleCurrentChange = (val: number) => {
  currentPage.value = val;
  getTenantAccount({
    Search: isSearch.value ? saveInputSearch.value : "",
    Status:
      activeFilter.value === "All"
        ? ""
        : activeFilter.value === "Invitation Sent"
        ? "Sent"
        : activeFilter.value,
    CountryCodes: optionFilter.value.country,
    ClientTypes: optionFilter.value.typeOfClient,
  });
};

const handleSortChange = (event: any) => {
  sortBy.value = event.prop;
  sortOrder.value = event.order === "ascending" ? 0 : 1;
  getTenantAccount({
    Search: isSearch.value ? saveInputSearch.value : "",
    Status:
      activeFilter.value === "All"
        ? ""
        : activeFilter.value === "Invitation Sent"
        ? "Sent"
        : activeFilter.value,
    CountryCodes: optionFilter.value.country,
    ClientTypes: optionFilter.value.typeOfClient,
    OrderBy: sortBy.value,
    OrderByDirection: sortOrder.value,
  });
};

const handleDetailAccountProfile = (_: number, tenantId: string) => {
  router.push(`${route.path}/${tenantId}/view-detail`);
};

const handleSearchCompany = () => {
  isSearch.value = true;
  saveInputSearch.value = searchText.value.trim();
  currentPage.value = 1;
  if (saveInputSearch.value === "") {
    return;
  }
  getTenantAccount({
    Search: saveInputSearch.value,
    Status:
      activeFilter.value === "All"
        ? ""
        : activeFilter.value === "Invitation Sent"
        ? "Sent"
        : activeFilter.value,
    CountryCodes: optionFilter.value.country,
    ClientTypes: optionFilter.value.typeOfClient,
  });
};

const getTenantAccount = (params?: ITenantParameters) => {
  TenantUserModule.getListTenantAction({
    ...params,
    CurrentPage: currentPage.value,
    Limit: pageSize.value,
    OrderBy: sortBy.value,
    OrderByDirection: sortOrder.value ?? 1,
  });
};

const filterOptionCountry = () => {
  countryListFilter.value = Object.entries(allCountries.value)
    .map(([key, item]) => {
      return {
        value: `${item.Value}`,
        text: `${item.Name}`,
      };
    })
    .slice()
    .sort((a, b) => a.text.localeCompare(b.text));
};

watch(allCountries, (newValue) => {
  if (!newValue) {
    return;
  }
  filterOptionCountry();
});

onMounted(() => {
  MetadataModule.getAllCountryAction();
  filterOptionCountry();
});

watch(searchText, (newVal) => {
  if (newVal.trim() === "") {
    isSearch.value = false;
    saveInputSearch.value = "";
    getTenantAccount({
      Status:
        activeFilter.value === "All"
          ? ""
          : activeFilter.value === "Invitation Sent"
          ? "Sent"
          : activeFilter.value,
      CountryCodes: optionFilter.value.country,
      ClientTypes: optionFilter.value.typeOfClient,
    });
  }
});

onMounted(() => {
  getTenantAccount();
});

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SearchInput, {
        class: "w-full",
        modelValue: searchText.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
        onSearch: handleSearchCompany,
        onClear: handleSearchCompany,
        placeholder: _unref(SEARCH_TEXT).search_by_company_name
      }, null, 8, ["modelValue", "placeholder"]),
      _createElementVNode("button", {
        class: "btn btn--green-primary rounded-sm px-4 py-2",
        onClick: _withModifiers(handleRegisterClient, ["prevent"])
      }, _toDisplayString(_unref(SYSTEM).register_client), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterOptions.value, (opt) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["w-full transition-colors bg-white", ['filter-btn', { 'filter-btn-active': activeFilter.value === opt }]]),
          key: opt,
          onClick: _withModifiers(($event: any) => (handleFilterStatus(opt)), ["prevent"])
        }, _toDisplayString(opt), 11, _hoisted_4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "w-full px-4",
        style: {
          borderRadius: '4px',
          borderColor: 'transparent',
          color: '#000000',
        },
        onSortChange: handleSortChange,
        data: tenantAccountList.value ? tenantAccountList.value : [],
        "max-height": "66vh",
        stripe: "",
        fit: true,
        scrolling: true,
        "cell-class-name": "text-cafe-dark",
        "row-class-name": "tableRow",
        "element-loading-text": "Loading...",
        "element-loading-background": "rgba(0, 0, 0, 0.6)"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            width: "400",
            prop: "CompanyName",
            label: "Company Name",
            sortable: "custom"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.CompanyName ? scope.row.CompanyName : "N/A"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            width: "300",
            prop: "CompanyRegistrationNumber",
            label: "Registration Number",
            sortable: "custom"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.CompanyRegistrationNumber
                ? scope.row.CompanyRegistrationNumber
                : "N/A"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "Country",
            width: "200"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["flex items-center gap-2 h-5", {
                'text-green-secondary': optionFilter.value.country.length > 0,
                '': optionFilter.value.country.length === 0,
              }])
              }, [
                _createElementVNode("span", {
                  class: "cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => {
                  visibleFilter.value.country = !visibleFilter.value.country;
                  visibleFilter.value.typeOfClient = false;
                }, ["prevent"]))
                }, "Country"),
                _createVNode(BaseSvgIcon, {
                  class: "w-4 cursor-pointer",
                  name: "filter",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => {
                  visibleFilter.value.country = !visibleFilter.value.country;
                  visibleFilter.value.typeOfClient = false;
                }, ["prevent"]))
                })
              ], 2),
              _createVNode(SelectFilter, {
                actions: countryListFilter.value,
                visible: visibleFilter.value.country,
                "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((visibleFilter.value.country) = $event)),
                onApply: handleFilterCountry
              }, {
                reference: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createElementVNode("div", null, null, -1)
                ])),
                _: 1
              }, 8, ["actions", "visible"])
            ]),
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.Country ? scope.row.Country : "N/A"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "Province",
            label: "Province",
            width: "200",
            sortable: "custom"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.Province ? scope.row.Province : "N/A"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "Country",
            width: "200"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["flex items-center gap-2 h-5", {
                'text-green-secondary': optionFilter.value.typeOfClient.length > 0,
                '': optionFilter.value.typeOfClient.length === 0,
              }])
              }, [
                _createElementVNode("span", {
                  class: "cursor-pointer",
                  onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => {
                  visibleFilter.value.typeOfClient = !visibleFilter.value.typeOfClient;
                  visibleFilter.value.country = false;
                }, ["prevent"]))
                }, "Type of Client"),
                _createVNode(BaseSvgIcon, {
                  class: "w-4 cursor-pointer",
                  name: "filter",
                  onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => {
                  visibleFilter.value.typeOfClient = !visibleFilter.value.typeOfClient;
                  visibleFilter.value.country = false;
                }, ["prevent"]))
                })
              ], 2),
              _createVNode(SelectFilter, {
                actions: _unref(TYPE_OF_CLIENT_FILTER),
                visible: visibleFilter.value.typeOfClient,
                "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((visibleFilter.value.typeOfClient) = $event)),
                onApply: handleFilterTypeOfClient
              }, {
                reference: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createElementVNode("div", null, null, -1)
                ])),
                _: 1
              }, 8, ["actions", "visible"])
            ]),
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.ClientType === 0
                ? _unref(SYSTEM).upstream_partner
                : _unref(SYSTEM).supplychain_partner), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "CreatedDate",
            sortable: "",
            label: "Created Date",
            "header-align": "right",
            align: "right",
            width: "200"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("p", null, _toDisplayString(_unref(formatInputDate)(scope.row.CreatedDate)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "Status",
            label: "Status",
            "header-align": "center",
            align: "center",
            width: "200"
          }, {
            default: _withCtx((scope) => [
              _createVNode(InvitationBadge, {
                status: scope.row.Status
              }, null, 8, ["status"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "Action",
            label: "Details",
            "header-align": "center",
            fixed: "right",
            "min-width": "200"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: _withModifiers(($event: any) => (
                  handleDetailAccountProfile(
                    scope.$index,
                    scope.row.ApplicationId
                  )
                ), ["prevent"])
                }, _cache[11] || (_cache[11] = [
                  _createElementVNode("p", { class: "text-green-secondary font-bold" }, "View", -1)
                ]), 8, _hoisted_7)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, isLoadingDataListTenant.value]
      ]),
      (tenantAccountList.value && tenantAccountList.value.length > 0)
        ? (_openBlock(), _createBlock(_component_el_pagination, {
            key: 0,
            currentPage: currentPage.value,
            "onUpdate:currentPage": _cache[7] || (_cache[7] = ($event: any) => ((currentPage).value = $event)),
            "page-size": pageSize.value,
            "onUpdate:pageSize": _cache[8] || (_cache[8] = ($event: any) => ((pageSize).value = $event)),
            "page-sizes": [10, 20, 30, 40],
            size: "small",
            disabled: disabled.value,
            background: false,
            layout: "sizes, slot, prev, pager, next",
            total: paginationGet.value.TotalRow,
            onSizeChange: handleSizeChange,
            onCurrentChange: handleCurrentChange,
            class: "px-4 py-3 flex items-center justify-between text-neutral-dark font-semibold"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", null, " Show " + _toDisplayString((currentPage.value - 1) * pageSize.value + 1) + " to " + _toDisplayString(Math.min(currentPage.value * pageSize.value, paginationGet.value.TotalRow)) + " of " + _toDisplayString(paginationGet.value.TotalRow) + " Items ", 1)
              ])
            ]),
            _: 1
          }, 8, ["currentPage", "page-size", "disabled", "total"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})