import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-block" }

import { STATUS_INVITATION } from "@ems/constants/account_registration_status";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvitationBadge',
  props: {
  status: {
    type: Number,
    required: true,
    validator: (value: number) =>
      [
        STATUS_INVITATION.Sent,
        STATUS_INVITATION.Pending,
        STATUS_INVITATION.Accepted,
        STATUS_INVITATION.Rejected,
        STATUS_INVITATION.Approved,
      ].includes(value),
  },
},
  setup(__props) {

const props = __props;

const statusClass = computed(() => {
  switch (props.status) {
    case STATUS_INVITATION.Sent:
      return "bg-status-inprogress";
    case STATUS_INVITATION.Pending:
      return "bg-status-pending";
    case STATUS_INVITATION.Accepted:
      return "bg-status-approved";
    case STATUS_INVITATION.Rejected:
      return "bg-status-rejected";
    case STATUS_INVITATION.Approved:
      return "bg-status-approved";
    default:
      return "";
  }
});

const statusMessage = computed(() => {
  switch (props.status) {
    case STATUS_INVITATION.Sent:
      return "Invitation Sent";
    case STATUS_INVITATION.Pending:
      return "Pending";
    case STATUS_INVITATION.Accepted:
      return "Accepted";
    case STATUS_INVITATION.Rejected:
      return "Rejected";
    case STATUS_INVITATION.Approved:
      return "Approved";
    default:
      return "";
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: _normalizeClass(["font-bold flex items-center justify-center w-28 h-8 rounded-md text-neutral-black", statusClass.value])
    }, _toDisplayString(statusMessage.value), 3)
  ]))
}
}

})