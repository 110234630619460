import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "option-list" }
const _hoisted_2 = {
  key: 0,
  class: "no-data"
}
const _hoisted_3 = { class: "mt-2 flex items-center justify-center gap-2" }

import { ref, computed, watch } from "vue";

import {
  ACTION_TYPE_FILTER,
  RELATED_FEATURE_FILTER,
} from "@ems/constants/filter";


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectFilter',
  props: {
  actions: { type: Array, required: true },
  visible: { type: Boolean, default: false },
},
  emits: ["apply", "update:visible"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const searchQuery = ref<string>("");
const selectedActions = ref<string[]>([]);
const popoverVisible = ref<boolean>(props.visible);
const isAllSelected = ref<boolean>(false);

const filteredActions = computed(() =>
  props.actions.filter((action: any) =>
    action.text.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
);

const toggleAll = () => {
  if (isAllSelected.value) {
    selectedActions.value = filteredActions.value.map(
      (action: any) => action.value
    );
  } else {
    selectedActions.value = [];
  }
};

const updateSelection = (value: string, checked: boolean) => {
  if (checked) {
    if (!selectedActions.value.includes(value)) {
      selectedActions.value.push(value);
    }
  } else {
    selectedActions.value = selectedActions.value.filter(
      (item) => item !== value
    );
  }
};

const cancelSelection = () => {
  searchQuery.value = "";
  popoverVisible.value = false;
};

const applySelection = () => {
  searchQuery.value = "";
  emit("apply", selectedActions.value);
  popoverVisible.value = false;
};

watch(
  () => props.visible,
  (newVal) => {
    popoverVisible.value = newVal;
  }
);

watch(popoverVisible, (newVal) => {
  emit("update:visible", newVal);
});

watch(selectedActions, () => {
  const totalOptions = filteredActions.value.length;
  const selectedCount = selectedActions.value.length;
  isAllSelected.value = selectedCount === totalOptions;
});

watch(filteredActions, () => {
  const totalOptions = filteredActions.value.length;
  const selectedCount = selectedActions.value.length;
  isAllSelected.value = totalOptions > 0 && selectedCount === totalOptions;
});

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (popoverVisible.value)
    ? (_openBlock(), _createBlock(_component_el_popover, {
        key: 0,
        visible: popoverVisible.value,
        "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((popoverVisible).value = $event)),
        placement: "bottom",
        width: 340,
        trigger: "click",
        "popper-class": "border-none rounded-lg shadow-xl py-2"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_el_input, {
              modelValue: searchQuery.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
              placeholder: "Search",
              class: "mb-2 w-full custom-placeholder h-9",
              clearable: ""
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_1, [
              ('all'.includes(searchQuery.value.toLowerCase()))
                ? (_openBlock(), _createBlock(_component_el_checkbox, {
                    key: 0,
                    modelValue: isAllSelected.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isAllSelected).value = $event)),
                    onChange: toggleAll
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" All ")
                    ])),
                    _: 1
                  }, 8, ["modelValue"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _createVNode(_component_el_checkbox_group, {
                  modelValue: selectedActions.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedActions).value = $event)),
                  onChange: _cache[3] || (_cache[3] = (value, checked) => updateSelection(value, checked)),
                  class: "flex flex-col"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredActions.value, (action) => {
                      return (_openBlock(), _createBlock(_component_el_checkbox, {
                        key: action.value,
                        label: action.value,
                        value: action.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(action.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (filteredActions.value.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, " No Data "))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                onClick: _withModifiers(cancelSelection, ["prevent"]),
                class: "btn border border-black bg-white text-neutral-black hover:bg-gray-200 py-1 px-4 rounded-md"
              }, " Cancel "),
              _createElementVNode("button", {
                onClick: _withModifiers(applySelection, ["prevent"]),
                class: "btn bg-green-secondary hover:bg-green-primary text-white py-1 px-5 rounded-md"
              }, " Apply ")
            ])
          ])
        ]),
        reference: _withCtx(() => [
          _renderSlot(_ctx.$slots, "reference")
        ]),
        _: 3
      }, 8, ["visible"]))
    : _createCommentVNode("", true)
}
}

})